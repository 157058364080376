import {Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {SET_MODAL_HOTSPOT, useThreeSixtyDispatch,} from "../App/ThreeSixtyContext";
import {Close} from "@mui/icons-material";
import {createUseStyles} from "react-jss";


const useStyles = createUseStyles({
  image: {
    objectFit: "contain",
    maxWidth: "85vw",
    maxHeight: "80vh",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginBlockStart: 0,
    wordBreak: "break-all",
  },
});

export const MuiModal = ({title="", children }) => {
  const dispatch = useThreeSixtyDispatch();
  const classes = useStyles()

  const closeHandler = () => {
    dispatch({ type: SET_MODAL_HOTSPOT, hotspotId: 0 });
  };

  return (
    <Dialog
      open={true}
      onClose={closeHandler}
      maxWidth={"xl"}
      scroll="paper"
      sx={{
        maxHeight: "100vh"
      }}
    >
      <DialogTitle>
        <p className={classes.title}>
          {title}
        </p>
        <IconButton
            aria-label="close"
            onClick={closeHandler}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: "black"
            }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
};
