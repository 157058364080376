import React from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    betaLogo: {
        filter: "drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.7))",
        position: "absolute",
        top: "76px",
        right: "24px",
        zIndex: 20
    },
})

export const BetaLogo = ({show=true}) => {
    const classes = useStyles()
    if(show) {
        return (
            <div
                className={classes.betaLogo}
            >
                <a href={"https://dok.digitaltmuseum.org/virtuelle-opplevelser"} target={"_blank"}>
                    <img src={"https://ems.dimu.org/image/019EBvjs37nME?mediaType=image/svg%2Bxml"} alt={"beta version of app"}/>
                </a>
            </div>
        )
    } else {
        return null
    }
}

